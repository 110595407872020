@font-face {
	font-family: 'Rubik';
	src: url('../fonts/Rubik-Regular.eot');
	src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Rubik-Regular.woff2') format('woff2'),
		url('../fonts/Rubik-Regular.woff') format('woff'),
		url('../fonts/Rubik-Regular.ttf') format('truetype'),
		url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik Medium';
	src: url('../fonts/Rubik-Medium.eot');
	src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Rubik-Medium.woff2') format('woff2'),
		url('../fonts/Rubik-Medium.woff') format('woff'),
		url('../fonts/Rubik-Medium.ttf') format('truetype'),
		url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik Light';
	src: url('../fonts/Rubik-Light.eot');
	src: url('../fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Rubik-Light.woff2') format('woff2'),
		url('../fonts/Rubik-Light.woff') format('woff'),
		url('../fonts/Rubik-Light.ttf') format('truetype'),
		url('../fonts/Rubik-Light.svg#Rubik-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy Light';
	src: url('../fonts/Gilroy-Light.eot');
	src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy-Light.woff2') format('woff2'),
		url('../fonts/Gilroy-Light.woff') format('woff'),
		url('../fonts/Gilroy-Light.ttf') format('truetype'),
		url('../fonts/Gilroy-Light.svg#Gilroy-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy ExtraBold';
	src: url('../fonts/Gilroy-ExtraBold.eot');
	src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Gilroy-ExtraBold.woff2') format('woff2'),
		url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
		url('../fonts/Gilroy-ExtraBold.ttf') format('truetype'),
		url('../fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
	font-weight: 800;
	font-style: normal;
}
